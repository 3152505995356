import React from 'react'

function FaqCard({title, children}) {
    return (
        <div className="w-full md:w-1/3 p-6 flex flex-col flex-shrink">
            <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden purple-shadow">
                <h3 className="w-full font-bold text-xl text-indigo-700 px-6 my-4 uppercase text-center">{title}</h3>
                <div className="w-full mb-4">
                    <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
                </div>
                <article className="text-gray-800 text-base px-6 mb-5">
                    {children}
                </article>
            </div>
        </div>
    )
}

export default FaqCard
