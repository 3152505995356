import React, {useState} from 'react'
import axios from 'axios'
import Loader from 'react-loader-spinner'
import { Formik } from "formik";
import style from "./ContactForm.module.scss"
import Modal from 'react-modal';
import * as Yup from "yup";
import contactImg from "../images/contact-créateur-site-internet.png"

const phoneRegExp = /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/

function ContactForm() {

  const [sended, setsended] = useState(null)
  const [modalIsOpen, setmodalIsOpen] = useState(false)
  const [loading, setloading] = useState(false)

  const customStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)',
      border                : 'none',
      background            : 'none'

    },
    overlay: {
      background            : 'linear-gradient(60.94deg, #007dfbc9 -62.93%, #661b80d9 51.64%, #661b80 213.13%)'
    },
  };

  return (
      <div>
          <Formik
              initialValues={{ email: "", firstname: "", lastname: "", phone: "", message: "" }}
              onSubmit={async (values, {resetForm}) => {
                try {
                  setloading(true)
                  setsended(null)
                  const response = await axios.post('/api/handelForm', values)
                  if(response.status === 201) {
                    setsended(true)
                    setmodalIsOpen(true)
                  } else {
                    setsended(false)
                  }
                  setloading(false)
                  resetForm()
                } catch (err) {
                  setsended(false)
                  setloading(false)
                }
              }}
              validationSchema={Yup.object().shape({
                firstname: Yup.string().min(3, "Minimum 3 caractères").required("Veuillez indiquer votre prénom"),
                lastname: Yup.string().min(3, "Minimum 3 caractères").required("Veuillez indiquer votre nom"),
                email: Yup.string().email("L'adresse email n'est pas valide").required("Veuillez indiquer une adresse email"),
                phone: Yup.string().matches(phoneRegExp, "Numéro de téléphone invalide"),
                message: Yup.string().max(1024)
          })}
        >
    {props => {
      const {
        values,
        touched,
        errors,
        dirty,
        isSubmitting,
        handleChange,
        handleBlur,
        handleSubmit,
        handleReset
      } = props;
      return (
        <form onSubmit={handleSubmit} className="relative">

        <div className={`container mx-auto flex flex-col pt-4 pb-12 ${style.formContainer}`}>
            <div className="flex flex-wrap justify-center my-10">
              <div className="flex  flex-wrap justify-center">
                <div className="mx-4 mb-4 sm:mb-0 w-full sm:w-auto">
                  <input
                    id="firstname"
                    name="firstname"
                    placeholder="Prénom *"
                    type="text"
                    value={values.firstname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={`input text-gray-800 w-full sm:w-auto`}  
                
                  />
                  {errors.firstname && touched.firstname && (
                    <div className="text-red-500 text-sm mt-2">{errors.firstname}</div>
                  )}
                </div>
                
                <div className="mx-4 mb-4 sm:mb-0 w-full sm:w-auto">
                  <input
                    id="lastname"
                    placeholder="Nom *"
                    type="text"
                    value={values.lastname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={`input text-gray-800 w-full sm:w-auto`}
                  />
                  {errors.lastname && touched.lastname && (
                    <div className="text-red-500 text-sm mt-2">{errors.lastname}</div>
                  )}
                </div>
              </div>
              
              <div className="flex flex-wrap justify-center">
                <div className="mx-4 mb-4 sm:mb-0 w-full sm:w-auto">
                  
                  <input
                    id="email"
                    placeholder="Adresse email *"
                    type="text"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={`input text-gray-800 w-full sm:w-auto`}
                  />
                  {errors.email && touched.email && (
                    <div className="text-red-500 text-sm mt-2">{errors.email}</div>
                  )}
                </div>
                
                <div className="mx-4 mb-4 sm:mb-0 w-full sm:w-auto">
                  <input
                    id="phone"
                    placeholder="Téléphone"
                    type="text"
                    value={values.phone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={`input text-gray-800  w-full sm:w-auto`}
                  />
                  {errors.phone && touched.phone && (
                    <div className="text-red-500 text-sm mt-2">{errors.phone}</div>
                  )}
                </div>
              </div>
            </div>
            
            <div className="flex flex-wrap justify-center">
              <div className="mx-4 mb-4 sm:mb-0 w-full">
                <textarea
                  id="message"
                  placeholder="Votre Message"
                  value={values.message}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`input text-gray-800  w-full sm:w-1/2`}
                  style={{height: "250px"}}
                />
                {errors.message && touched.message && (
                  <div className="text-red-500 text-sm mt-2">{errors.message}</div>
                )}
              </div>
            </div>
            
            </div>
           <div>
             {sended === false ? 
             
              <div className="bg-red-500 w-max mx-auto rounded-lg p-5 mb-5">
                <p>
                  Oups ! Une erreur a empêché d'envoyer votre message. <br />
                  Vous pouvez m'envoyez un mail à contact@j-rd.fr en attendant que le problème soit résolu.
                </p>
              </div>
             
             : null}
             
              <button type="submit" className="mx-auto lg:mx-0 hover:underline rounded-full mt-4 lg:mt-0 py-4 px-8 purple-shadow opacity-75 gradient text-white uppercase anton tracking-widest text-xl transform hover:scale-125 transition duration-150 focus:outline-none focus:ring focus:border-blue-300">Envoyer {loading ? <Loader type="Circles" color="#00BFFF" height={80} width={80}/> : null }</button>
           </div>
          
        </form>
      );
    }}
  </Formik>
  <Modal
    isOpen={modalIsOpen}
    style={customStyles}
    contentLabel="Message envoyé"
    >
      <div onClick={() => setmodalIsOpen(false)} className={style.closeButton} />
      <div className={`flex flex-col sm:flex-row max-h-screen  ${style.modalBody}`}>
        <img src={contactImg}/>
        <div className="flex flex-col justify-center mt-4 sm:mt-0:;">
            <p className="text-gray-100 text-4xl anton uppercase mb-10 underline">Merci de m'avoir contacté</p>
            <p className="text-gray-300 text-2xl anton">Votre message a bien été envoyé. <br />
            Je reviendrai vers vous rapidement.</p>
        </div>
      </div>
    </Modal>
  </div>
  )
}

export default ContactForm
