import React from 'react'
import ContactForm from '../components/ContactForm'
import {Helmet} from 'react-helmet'
import Layout from "../components/layout";
import FaqCard from "../components/FaqCard"
import imageContact from "../images/contacter-agence-web-hautes-alpes-j-rd.png"
import imageContactByMail from "../images/contacter-agence-web-hautes-alpes-j-rd-mail.png"

function Contact() {
    return (
        <>
            <Helmet>
                <title>Contacter agence web Hautes alpes | J-RD</title>
                <meta name="description" content="Agence web, réation de site internet, prise en charge de la création de votre site web de A à Z : site vitrine, blog, e-commerce, site sur mesure, refonte de site existant" />
            </Helmet>
            <Layout page='contact'>
                <section className="bg-gray-50  text-center w-full pb-10 border-b">
                    <h1  className="w-full pt-10  mb-5 text-4xl sm:text-5xl font-bold leading-tight text-center text-gray-600">Contacter votre agence web des Hautes Alpes J-RD</h1>
                    <div className="w-full mb-10">
                        <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
                    </div>
                    <img className="rounded-full inline-block mx-auto h-60" alt="contacter agence web hautes alpes j-rd" src={imageContact} />
                </section>
                <section className="bg-white  text-center w-full pb-10 border-b">
                    <h2 style={{color: "#ffa543"}} className="text-3xl text-gray-600 py-10 px-10 font-bold">Agence web située dans les Hautes Alpes à Gap. <br />Création de site internet dans les Hautes alpes, Gap, Briançon, région PACA et toute la france</h2>
                    <h3 className="leading-normal text-xl sm:text-2xl mb-2 sm:mb-4 text-gray-600 relative wPuce anton uppercase">Site vitrine</h3>
                    <h3 className="leading-normal text-xl sm:text-2xl mb-2 sm:mb-4 text-gray-600 relative wPuce anton uppercase">Blog</h3>
                    <h3 className="leading-normal text-xl sm:text-2xl mb-2 sm:mb-4 text-gray-600 relative wPuce anton uppercase">E-commerce</h3>
                    <h3 className="leading-normal text-xl sm:text-2xl mb-2 sm:mb-4 text-gray-600 relative wPuce anton uppercase">Site sur mesure</h3>
                    <h3 className="leading-normal text-xl sm:text-2xl mb-2 sm:mb-4 text-gray-600 relative wPuce anton uppercase">Refonte de site internet</h3>
                    <h2 style={{color: "#ffa543"}} className="text-3xl text-gray-600 mt-12 mb-6 px-10 font-bold">Vous avez des question, vous souhaitez un devis pour la création de votre site internet ?</h2>
                    
                    <p className="text-xl text-gray-700">Bonjour, c'est jérémy. <br/> Vous avez un projet ou des questions. Vous souhaitez un devis gratuit ? N'hésitez pas à me contacter je reviendrai vers vous rapidement</p>
                    <h2  style={{color: "#ffa543"}} className="text-3xl text-gray-600 mt-12 px-10 font-bold mb-10">Contacter l'agence web via le formulaire</h2>
                    <img className="h-50 inline-block mx-auto" src={imageContactByMail} alt="contacter agence web hautes alpes par mail"/>
                    <ContactForm />
                    <h2 style={{color: "#ffa543"}} className="text-3xl text-gray-600 mt-10 px-10 font-bold">Contacter l'agence par téléphone</h2>
                    <div className="mt-10">
                        <p className="leading-normal text-xl sm:text-2xl mb-2 sm:mb-4 text-gray-600 relative wPuce anton uppercase">06 63 46 68 12</p>
                    </div>
                </section>
                <section className="border-b py-8 bg-gray-50">

                    <div className="container mx-auto flex flex-wrap pt-4 pb-12">

                    <h2 className="w-full my-2 text-4xl sm:text-5xl font-bold leading-tight text-center text-gray-800">FAQ - Foire aux questions</h2>
                    <div className="w-full mb-4">
                        <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
                    </div>
                    <FaqCard title="Combien coûte un site internet ?">
                        <p>
                        Et bien ça dépend ! <br />
                        Je ne peux répondre à cette question sans connaître votre besoin. <br /> 
                        Le coût d’un site dépend à la fois du type de site web que vous souhaitez et également du niveau de spécificité que l’on y intègre.<br />
                        En résumé c’est du cas par cas. <br />
                        Méfiez-vous si on vous propose un prix sans avoir au préalable analysé votre besoin. <br />
                        Il y a de forte chance que, soit vous allez payer plus cher que vous ne le devriez et/ou que vous vous retrouviez avec un site qui n’est pas totalement adapté à votre besoin.  
                        </p>
                    </FaqCard>
                    <FaqCard title="En combien de temps un site est-il réalisé ?">
                        <p>
                        Aussi rapidement que possible bien sûr, cela peut aller de quelques jours pour un site vitrine standard, à plusieurs semaines pour un site sur mesure.
                        <br/>
                        <br/> 
                        Encore une fois, cela dépend de ce que vous souhaitez faire avec votre site internet.
                        <br/>
                        <br/>
                        Je vous communiquerais régulièrement l'état de l'avancée de votre projet pour toujours savoir ou cela en est.
                        </p>
                    </FaqCard>
                    <FaqCard title="Pourquoi avoir un site internet ? Qu'est-ce que cela va m'apporter ?">
                        <p>
                        Voici quelques points qui soulignent l'importance d'avoir un site web :
                        </p>
                        <ul className="my-3">
                        <li>Un site internet peut être consulté par 100% des internautes, contrairement aux réseaux sociaux.</li>
                        <li>Un site internet vous permet de gagner en notoriété et d’inspirer confiance et de maîtriser votre communication en ligne.</li>
                        <li>Un site internet vous permet de générer des prospects</li>
                        <li>Un site vous permet de vendre en ligne avec une boutique disponible <span style={{whiteSpace: "nowrap"}}>24h sur 24</span> <span style={{whiteSpace: "nowrap"}}>7 jours sur 7</span></li>
                        </ul>
                    </FaqCard>

                    <FaqCard title="Quel créateur de site internet choisir ?">
                        <p>
                        Faite appel à un créateur qui va prendre en compte vos besoins, pour developper un produit qui vous correspond à 100% <br />
                        Ici, Je ne vends pas de site générique qui sont des clones les uns des autres. <br />
                        Je prends en compte vos besoins en les analysants, pour produire le résultat dont vous avez besoin. 
                        </p>
                    </FaqCard>

                    <FaqCard title="Pourquoi avoir un site responsive design ?">
                        <p>
                        Avec plus de <a className="base-link" href="https://www.mediametrie.fr/fr/lannee-internet-2019">37.4 millions d'internautes qui utilisent des appareils mobiles</a> pour naviguer sur le web et 4 internautes sur 10 utilisent exclusivement leur mobile pour surfer sur le web. <br/>
                        On comprend donc qu'aujourd'hui avoir un site responsive, qui s'adapte à tous les formats d'écran est indispensable. <br />
                        Si votre site internet ne s'affiche pas correctement et n'est pas ergonomique, vos utilisateurs ne s'attarderont pas dessus.<br/>
                        De plus un site responsive et un plus au niveau du SEO et sera mieux référencé sur les moteurs de recherche.
                        </p>
                    </FaqCard>

                    <FaqCard title="Comment choisir le design de mon site ?">
                        <p>
                        Je vous propose une série de <strong>template</strong> (modèle) prédéfinies parmi lesquels vous pourrez choisir le style que vous souhaitez donner à votre site internet. <br/>
                        Une fois le design de base sélectionné je l'adapte à votre image et à votre identité visuelle. <br />
                        Si vous souhaitez un design totalement original, je peux intégrer un modèle que vous vous serez procuré auprès d'un designer, ou bien créer moi-même un design sur mesure selon vos préférences.
                        </p>
                    </FaqCard>

                    <FaqCard title="Je n’ai aucune connaissance en programmation, comment faire ?">
                        <p>
                        N'ayez aucune crainte, si vous vous sentez un petit peu perdu et que vous ne savez pas par où commencer je suis là pour vous guider et vous former. <br />
                        Je prends en charge votre projet de création de A à Z.
                        </p>
                    </FaqCard>

                    
                    </div>
                </section>
            </Layout>
        </>
    )
}

export default Contact
